const Blog = (props) => {
  return ( 
    <main>
      <embed 
        type="application/pdf" 
        src="./Pseudocode.pdf"
        height="100%"
        width="100%"
      />
    </main>

   );
}

export default Blog;